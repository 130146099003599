// .row {
//     display: flex;
//     flex-wrap: wrap;
//     margin-right: -15px;
//     margin-left: -15px;
// }

.footer_area {
  background: #000410;
  height: auto;
}
.p_footer {
  padding-top: 60px;
  padding-bottom: 60px;
}

.f_title {
  margin-bottom: 35px;
}

.f_title h3 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  font-family: "Playfair Display", serif;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.ab_widget p {
  font-size: 16px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  color: #777777;
  margin-bottom: 30px;
  text-align: justify;
}

aside {
  display: block;
}

.f_titlefooter {
  margin-bottom: 35px;
}

.f_titlefooter h3 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  font-family: "Playfair Display", serif;
  margin-bottom: 0px;
  position: relative;
  left: 20px;
  text-transform: uppercase;
}

.social_widget p {
  font-size: 16px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  color: #777777;
  margin-bottom: 10px;
}

.f_titlefooter p {
  font-size: 14px;
  font-family: "Playfair Display", serif;
  color: #777777;
  margin-bottom: 0px;
  position: relative;
  left: 20px;
}
