$dip: #222222;
$pfont: #777777;

$baseColor: #8490ff;
@mixin transition(
  $property: all,
  $duration: 300ms,
  $animate: linear,
  $delay: 0s
) {
  transition: $property $duration $animate $delay;
}

// Placeholder Mixins

@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1620px) {
  .box_1620 {
    max-width: 1650px;
    margin: auto;
  }
}

.breadcrumbs {
  float: inherit;
}

.breadcrumb > li + li:before {
  // color: #ccc;
  content: "\2192 " !important;
  padding: 0 5px;
}

a input[type="button"]:hover {
  text-decoration: none !important;
}

.breadcrumb {
  background-color: #f9f9ff !important;
  display: block !important;
  text-align: center;
  margin-bottom: 0px !important;
  padding: 0px 0px !important;
  li {
    a {
      font-size: 14px;
      color: #777777;
      font-family: "Open Sans", sans-serif;
      text-decoration: none !important;
    }
  }
}

.headerBreadC {
  color: #222222;
  font-size: 36px;
  font-family: "Playfair Display", serif;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: bold;
}

.fonsS1 {
  font-size: 14px;
}

.home_banner_area {
  z-index: 1;
  background: url(../images/Banner/home-banner.jpg) no-repeat scroll center
    center;
  background-size: cover;
  .banner_inner {
    width: 100%;
    .home_left_img {
      padding-top: 195px;
    }
    .col-lg-6 {
      vertical-align: middle;
      align-self: center;
    }
    .banner_content {
      text-align: left;
      color: $dip;
      h5 {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        text-transform: uppercase;
        letter-spacing: 2.1px;
        margin-bottom: 20px;
        font-weight: 700;
      }
      h2 {
        margin-top: 0px;
        font-size: 48px;
        font-weight: 700;
        font-family: "Playfair Display", serif;
        line-height: 54px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      p {
        color: $pfont;
        font-family: "Open Sans", sans-serif;
        max-width: 680px;
        font-size: 16px;
        line-height: 26px;
        margin: 0px auto 45px;
      }
      .banner_map_img {
        text-align: right;
      }

      .banner_btn {
        padding: 0px 42px;
        line-height: 50px;
        background-image: linear-gradient(
          to right,
          #8490ff 0%,
          #62bdfc 48%,
          #8490ff 100%
        );
        background-size: 200% auto;
        color: #fff;
        display: inline-block;
        border-radius: 5px;
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        text-decoration: none;
        @include transition;
        &:hover {
          background-position: right center;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  /* Home Banner Area css
      ============================================================================================ */
  .home_banner_area .banner_inner .banner_content h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .home_banner_area {
    min-height: 450px;
  }
  .home_banner_area .banner_inner {
    min-height: 450px;
  }
  .blog_banner .banner_inner .blog_b_text {
    margin-top: 0px;
  }
  .home_banner_area .banner_inner img {
    display: none;
  }
  .home_banner_area .banner_inner .banner_content h5 {
    margin-top: 0px;
  }
}

@media (max-width: 480px) {
  /* Main Menu Area css
      ============================================================================================ */
  .header_area .navbar-collapse {
    max-height: 250px;
  }
  /* End Main Menu Area css
      ============================================================================================ */

  /* Home Banner Area css
      ============================================================================================ */
  .home_banner_area .banner_inner .banner_content {
    padding: 30px 15px;
    margin-top: 0px;
  }
  .home_banner_area .banner_inner .banner_content h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .banner_content .white_btn {
    display: block;
  }
  .home_banner_area .banner_inner .banner_content h3 {
    font-size: 24px;
  }
}
