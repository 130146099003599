$content-width: 1000px;
$breakpoint: 799px;
$nav-height: 70px;
// $nav-background: #262626;
$nav-font-color: black;
// $nav-font-color: #ffffff;
$link-hover-color: #2581dc;
$nav-background: #f9f9ff;
.navigation {
  height: $nav-height;
  background: $nav-background;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.nav-container {
  max-width: $content-width;
  margin: 0 auto;
}
.brand {
  position: absolute;
  padding-left: 20px;
  float: left;
  line-height: $nav-height;
  text-transform: uppercase;
  font-size: 1.4em;
  a,
  a:visited {
    color: $nav-font-color;
    text-decoration: none;
  }
}

nav {
  float: right;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      float: left;
      position: relative;
      a {
        display: block;
        padding: 0 20px;
        line-height: $nav-height;
        background: $nav-background;
        color: $nav-font-color;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        &:hover {
          // background: $link-hover-color;
          color: $nav-font-color;
        }
        &:not(:only-child):after {
          padding-left: 4px;
          content: " ▾";
        }
      }
      ul li {
        min-width: 190px;
        a {
          padding: 15px;
          line-height: 20px;
        }
      }
    }
  }
}

.nav-dropdown {
  position: absolute;
  z-index: 999;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  display: none;
}

.nav-mobile {
  //display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: $nav-background;
  height: $nav-height;
  width: $nav-height;
}
@media only screen and (max-width: $breakpoint) {
  .nav-mobile {
    display: block;
  }

  nav {
    width: 100%;
    padding: $nav-height 0 15px;
    ul {
      display: none;
      li {
        float: none;
        a {
          padding: 15px;
          line-height: 20px;
        }
        ul li a {
          padding-left: 30px;
        }
      }
    }
  }

  .nav-dropdown {
    position: static;
  }
  // Insert all mobile styles here
  nav {
    ul {
      display: none;
    }
  }
  #nav-toggle {
    position: absolute;
    left: 18px;
    top: 22px;
    cursor: pointer;
    padding: 10px 35px 16px 0px;
    span,
    span:before,
    span:after {
      cursor: pointer;
      border-radius: 1px;
      height: 5px;
      width: 35px;
      background: $nav-font-color;
      position: absolute;
      display: block;
      content: "";
      transition: all 300ms ease-in-out;
    }
    span:before {
      top: -10px;
    }
    span:after {
      bottom: -10px;
    }
    &.active span {
      background-color: transparent;
      &:before,
      &:after {
        top: 0;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

@media screen and (min-width: $breakpoint) {
  .nav-list {
    display: block !important;
  }
}
