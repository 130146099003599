/*--------------------------------------------------------------
# Resume
--------------------------------------------------------------*/
.resume .resume-title {
  font-size: 26px;
  font-weight: 500 !important;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}

.resume .resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid #8490ff;
  position: relative;
}

.resume .resume-item h4 {
  line-height: 18px;
  font-size: 20px;
  font-weight: 600;
  color: #343535;
  margin-bottom: 10px;
  font-family: "Playfair Display", serif;
}

.resume .resume-item h5 {
  font-size: 18px !important;
  background: #f9f9ff;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 400 !important;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.resume .resume-item p {
  font-size: 16px !important;
  text-align: justify;
}

.resume .resume-item ul {
  padding-left: 20px;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  left: -13px;
  top: 0;
  border: 2px solid #8490ff;
  background: #fff;
}

.resume .resume-item .jop::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #62bdfc;
  content: "\f0b1";
  position: absolute;
  left: -7px;
  top: -1px;
}

.resume .resume-item .education::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #62bdfc;
  content: "\f19d";
  position: absolute;
  left: -9px;
  top: -1px;
}

.resume .resume-item .blog::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #62bdfc;
  content: "\f086";
  position: absolute;
  left: -9px;
  top: -1px;
}
